/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../styles/index.css';
import Layout from "../components/Layout";
import '@brainhubeu/react-carousel/lib/style.css';
import {Link} from "gatsby";

const Bakery = ({}) => {
    return (
        <>
            <Layout location={"/your-bakery"} type={"boulangeries"}>
                <div>
                    <div className="fournil d_0">
                        <div className="double">
                            <div>
                                <img src="/images/e18092-35m2-345x390.jpg"
                                     srcSet="/images/e18092-35m2-689x780.jpg 2x"
                                     alt="Площадь пекарни 30 м²"/></div>
                            <div><h3></h3>
                                <h2 className="h1">30 м²</h2>
                                <p>Пример для плоащади 30 м²</p>
                                <p className="nmgb">
                                    <a href="https://www.bongard.fr/en/your-backery/baking-area-of-35m%c2%b2/"
                                       className="btn">Узнать больше</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fournil d_1">
                        <div className="double">
                            <div>
                                <img src="/images/ep17206e-50m2-355x390.jpg"
                                     srcSet="/images/ep17206e-50m2.jpg 2x"
                                     alt="Площадь пекарни 50 м²"/></div>
                            <div><h3></h3>
                                <h2 className="h1">50 м²</h2>
                                <p>Пример для плоащади 50 м²</p>
                                <p className="nmgb">
                                    <a href="https://www.bongard.fr/en/your-backery/baking-area-of-50-m%c2%b2/"
                                       className="btn">Узнать больше</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fournil d_0">
                        <div className="double">
                            <div>
                                <img src="/images/ep16216-80m2-547x390.jpg"
                                     srcSet="/images/ep16216-80m2-1093x780.jpg 2x"
                                     alt="Площадь пекарни 80 м²"/></div>
                            <div>
                                <h3></h3>
                                <h2 className="h1">80 м²</h2>
                                <p>Пример для плоащади 80 м²</p>
                                <p className="nmgb"><a
                                    href="https://www.bongard.fr/en/your-backery/example-of-a-bakery-80-m%c2%b2/"
                                    className="btn">Узнать больше</a></p></div>
                        </div>
                    </div>
                    <div className="fournil d_1">
                        <div className="double">
                            <div>
                                <img src="/images/ep15277g-100m2-385x390.jpg"
                                     srcSet="/images/ep15277g-100m2-770x780.jpg 2x"
                                     alt="Площадь пекарни 100м²"/></div>
                            <div>
                                <h3></h3>
                                <h2 className="h1">100м²</h2>
                                <p>Пример для плоащади 100 м²</p>
                                <p className="nmgb">
                                    <a href="https://www.bongard.fr/en/your-backery/example-of-a-bakery-100m%c2%b2/"
                                       className="btn">Узнать больше</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="projet"
                         style={{"background-image": "url(/images/visuel-fournil.jpg)"}}>
                    <div>
                        <span className="h4 nmgb">Новая пекарня</span>
                        <h2 className="h1">Остались вопросы?</h2>
                        <p className="mgt2x">
                            <Link to={"/contact-us"} className="btn">Свяжитесь с нами</Link>
                        </p>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Bakery;
